import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import { SelectDeliverySection } from '../components/sections';

const SelectDeliveryPage = (): JSX.Element => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <SEO title="Select Delivery" />
      <SelectDeliverySection />
    </Layout>
  );
};

export default SelectDeliveryPage;
